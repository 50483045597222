<template>
  <div>
    <el-card style="width: 60%">
          <template #header>
            <span style="font-size: small">{{$t('msg.route.legCodeManage')}}</span>
            <el-button type="primary" size="small" style="float: right" @click="addRecord()">{{ $t('msg.basicInfo.addRecord') }}</el-button>
          </template>
          <el-table :data="legCodeList">
            <el-table-column prop="legcode" :label="$t('msg.chargeCode.legcode')" sortable>
              <template #default="scope">
                <el-input v-model="scope.row.legcode" size="small" v-if="scope.$index+editFlag===-1"></el-input>
                <span v-else>{{scope.row.legcode}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="legname" :label="$t('msg.chargeCode.legname')" sortable>
              <template #default="scope">
                <el-input v-model="scope.row.legname" size="small" v-if="scope.$index+editFlag===-1"></el-input>
                <span v-else>{{scope.row.legname}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="legtype" :label="$t('msg.chargeCode.legtype')" sortable>
              <template #default="scope">
                <el-select v-model="scope.row.legtype" size="small" v-if="scope.$index+editFlag===-1">
                  <el-option v-for="item in uniqueLegTypes" :key="item" :label="item" :value="item" />
                </el-select>
                <span v-else>{{scope.row.legtype}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="legseq" :label="$t('msg.chargeCode.legseq')" sortable></el-table-column>
            <el-table-column fixed="right" :label="$t('msg.rate.Operations')" width="200px" align="center">
              <template #default="scope">
                <el-button type="success" size="small" @click="confirmEdit(scope.row)" plain v-if="scope.$index+editFlag===-1">Confirm</el-button>
                <el-button type="danger" size="small" @click="cancelEdit()" plain v-if="scope.$index+editFlag===-1">Cancel</el-button>
                <el-button type="primary" size="small" @click="startEdit(scope.$index)" v-if="scope.$index+editFlag!==-1">Edit</el-button>
                <el-button type="danger" size="small" @click="delRecord(scope.row)" v-if="scope.$index+editFlag!==-1">Del</el-button>
              </template>
            </el-table-column>
          </el-table>
    </el-card>
    <el-dialog title="Add Record" v-model="updateFormShow" v-on:close="resetForm()" width="30%" center draggable>
      <el-form :model="updateForm" label-position="top" label-width="200px">
        <el-row>
          <el-form-item :label="$t('msg.chargeCode.legcode')" prop="legcode">
            <el-input v-model="updateForm.legcode" size="small"></el-input>
          </el-form-item>
          <el-form-item :label="$t('msg.chargeCode.legname')" prop="legname">
            <el-input v-model="updateForm.legname" size="small" style="margin-left: 20px"></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item :label="$t('msg.chargeCode.legtype')" prop="legtype">
            <el-select v-model="updateForm.legtype" size="small">
              <el-option v-for="item in uniqueLegTypes" :key="item" :label="item" :value="item" />
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('msg.chargeCode.legseq')" prop="legseq">
            <el-input-number v-model="updateForm.legseq" size="small" controls-position="right" :min="1" :step="1" style="width: 100%;margin-left: 20px"></el-input-number>
          </el-form-item>
        </el-row>
     </el-form>
      <el-button type="primary" v-on:click="recordSubmit()" style="float: right">Submit</el-button>
    </el-dialog>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import axios from 'axios'
export default defineComponent({
  name: 'legCodeManage',
  setup() {
    const editFlag = ref(0)
    const legCodeList = ref([{ legcode: '', legname: '', legtype: '', legseq: 0 }])
    const uniqueLegTypes = ref([])
    const updateFormShow = ref(false)
    const updateForm = ref({ legcode: '', legname: '', legtype: '', legseq: 0 })
    const initPage = () => {
      const reqURL = process.env.VUE_APP_BASE + '/charCode/getLegCode'
      axios.get(reqURL).then((response) => {
        legCodeList.value = response.data.legCodeList
        uniqueLegTypes.value = response.data.uniqueLegTypes
        console.log(legCodeList.value)
      }).catch((response) => {
        console.log(response)
      })
    }
    const addRecord = () => {
      if (editFlag.value < 0) {
        alert('Please click comfirm or cancel to close opened input first!')
        return false
      }
      updateFormShow.value = true
    }
    const resetForm = () => {
      editFlag.value = 0
      updateForm.value = { legcode: '', legname: '', legtype: '', legseq: 0 }
    }
    const recordSubmit = () => {
      for (let n = 0; n < legCodeList.value.length; n++) {
        if (legCodeList.value[n].legseq === updateForm.value.legseq) {
          alert('legSeq should be unique, please fill legSeq again')
          return false
        }
      }
      const param = 'newLegCode=' + JSON.stringify(updateForm.value)
      const reqURL = process.env.VUE_APP_BASE + '/charCode/addNewLegCode'
      axios.post(reqURL, param).then((response) => {
        legCodeList.value = response.data
        updateFormShow.value = false
      }).catch((response) => {
        console.log(response)
      })
    }
    const startEdit = id => {
      if (editFlag.value < 0) {
        alert('Please click comfirm or cancel to close opened input first!')
        return false
      }
      editFlag.value = -(id + 1)
    }
    const cancelEdit = () => {
      editFlag.value = 0
      initPage()
    }
    const confirmEdit = (row) => {
      editFlag.value = 0
      const param = 'updatedLegCode=' + JSON.stringify(row)
      const reqURL = process.env.VUE_APP_BASE + '/charCode/updateLegCode'
      axios.post(reqURL, param).then((response) => {
        legCodeList.value = response.data
      }).catch((response) => {
        console.log(response)
      })
    }
    const delRecord = row => {
      const param = 'legSeg=' + row.legseq
      const reqURL = process.env.VUE_APP_BASE + '/charCode/deleteByLegSeg'
      axios.post(reqURL, param).then((response) => {
        legCodeList.value = response.data
      }).catch((response) => {
        console.log(response)
      })
    }
    onMounted(() => {
      initPage()
    })
    return { editFlag, legCodeList, uniqueLegTypes, updateFormShow, updateForm, resetForm, initPage, addRecord, recordSubmit, cancelEdit, confirmEdit, startEdit, delRecord }
  }
})
</script>
